// BioPageHeader.jsx
// TK 9/29/2k22

// Packages, Dependencies, and Shared Libraries
import React from "react";
import { useNavigate } from "react-router-dom";

// Images
import logo from "../../Images/HomePage/LeafLings.png";
import instagramLogo from "../../Images/SocialMedia/instagram_glyph.png";
import tiktokLogo from "../../Images/SocialMedia/tiktokLogo.png";
import SocialMediaIcon from "../../HomePage/SocialMediaIcon";

// Constants
import * as homePageConstants from "../../constants";

// CSS
import "./Header.css";

/*******************************************************************************
 * @func PlantBioPageHeader (React Component)
 * This component has the logo and the social media icons that appear at the top
 *  of the page. Clicking on the logo takes the user home and clicking on the
 * social media icons takes them to the social media page.
 *
 * @param None
 * @return JSX Element
 *
 * @notes None
 *
 ******************************************************************************/
const BioPageHeader = () => {
  // Constants
  const navigate = useNavigate();

  return (
    <header>
      <img
        className="headerLogo"
        src={logo}
        alt="logo"
        onClick={() => {
          navigate("/");
        }}
      />
      <div className="bioSocialMedias">
        <SocialMediaIcon
          logoRelPath={instagramLogo}
          link={homePageConstants.INSTAGRAM_URL}
        />
        {/* <SocialMediaIcon
          logoRelPath={tiktokLogo}
          link={homePageConstants.TIKTOK_URL}
        /> */}
      </div>
    </header>
  );
}; // End PlantBioPageHeader

export default BioPageHeader;
